const palette = {
  'color-background': '#2b2e34',
  'color-surface': '#3f4349',
  'color-onSurface': '#1D1F30',
  'color-text': '#ffffff',
  'color-placeholder': '#dadada',
  'color-disabled': '#bbbdbf',
  'color-notification': '#000000',
  'color-primary-100': '#c5ddef',
  'color-primary-200': '#7ab5de',
  'color-primary-300': '#4697ce',
  'color-primary-400': '#1f7bb9',
  'color-primary-500': '#00548e',
  'color-primary-600': '#013c65',
  'color-primary-700': '#022944',
  'color-primary-800': '#032034',
  'color-primary-900': '#00548e',
  'color-success-100': '#c1ecce',
  'color-success-200': '#95e5ac',
  'color-success-300': '#53d77a',
  'color-success-400': '#29c758',
  'color-success-500': '#009c2e',
  'color-success-600': '#028128',
  'color-success-700': '#046922',
  'color-success-800': '#074f1c',
  'color-success-900': '#083415',
  'color-info-100': '#e8e8f3',
  'color-info-200': '#cecfea',
  'color-info-300': '#b4b5de',
  'color-info-400': '#9899cc',
  'color-info-500': '#8586ba',
  'color-info-600': '#696a9f',
  'color-info-700': '#494a79',
  'color-info-800': '#2d2e50',
  'color-info-900': '#1f1f38',
  'color-warning-100': '#f0f6d0',
  'color-warning-200': '#e8f6ac',
  'color-warning-300': '#e0f387',
  'color-warning-400': '#d1e764',
  'color-warning-500': '#bdd245',
  'color-warning-600': '#8b9a27',
  'color-warning-700': '#697516',
  'color-warning-800': '#4f570d',
  'color-warning-900': '#313606',
  'color-danger-100': '#fff2e4',
  'color-danger-200': '#ffe1bc',
  'color-danger-300': '#ffcc93',
  'color-danger-400': '#ffb65c',
  'color-danger-500': '#ffa534',
  'color-danger-600': '#e79122',
  'color-danger-700': '#c07410',
  'color-danger-800': '#834d07',
  'color-danger-900': '#4d2d03',
};

export default palette;
