const Clients = {
  DEFAULT: 'default',
  CAT: 'cat',
  FASTBACK: 'fastback',
  ALPHA: 'alpha',
  ALGODRIVEN_CAPTURE: 'algodriven_capture',
  ALGODRIVEN_REPORT: 'algodriven_report',
  VIDEO_POC: 'video_poc',
  HITL_DEMO: 'hitl_demo',
};

export default Clients;
