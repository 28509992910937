const palette = {
  'color-background': '#181829',
  'color-surface': '#313240',
  'color-onSurface': '#1D1F30',
  'color-text': '#fafafa',
  'color-placeholder': '#dadada',
  'color-disabled': '#bbbdbf',
  'color-notification': '#000000',
  'color-primary-100': '#D4E3FB',
  'color-primary-200': '#ABC6F8',
  'color-primary-300': '#7EA1EB',
  'color-primary-400': '#5A7FD8',
  'color-primary-500': '#2B52BE',
  'color-primary-600': '#1F3EA3',
  'color-primary-700': '#152D88',
  'color-primary-800': '#0D1F6E',
  'color-primary-900': '#08155B',
  'color-success-100': '#E4FBD4',
  'color-success-200': '#C4F7AB',
  'color-success-300': '#98E97D',
  'color-success-400': '#6DD359',
  'color-success-500': '#36B72A',
  'color-success-600': '#1F9D1E',
  'color-success-700': '#15831D',
  'color-success-800': '#0D6A1C',
  'color-success-900': '#08571B',
  'color-info-100': '#CAFCF3',
  'color-info-200': '#96FAF0',
  'color-info-300': '#61F2EF',
  'color-info-400': '#39DCE6',
  'color-info-500': '#00B9D6',
  'color-info-600': '#0090B8',
  'color-info-700': '#006C9A',
  'color-info-800': '#004E7C',
  'color-info-900': '#003866',
  'color-warning-100': '#FDF9CB',
  'color-warning-200': '#FCF299',
  'color-warning-300': '#F8E665',
  'color-warning-400': '#F1D83F',
  'color-warning-500': '#E8C504',
  'color-warning-600': '#C7A602',
  'color-warning-700': '#A78802',
  'color-warning-800': '#866B01',
  'color-warning-900': '#6F5700',
  'color-danger-100': '#FDE8CE',
  'color-danger-200': '#FBCB9F',
  'color-danger-300': '#F4A56E',
  'color-danger-400': '#EA7F49',
  'color-danger-500': '#DD4913',
  'color-danger-600': '#BE300D',
  'color-danger-700': '#9F1C09',
  'color-danger-800': '#800D06',
  'color-danger-900': '#6A0304',
};

export default palette;
