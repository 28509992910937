const info = {
  appName: 'Video Poc Application',
  shortName: 'videoPoc',
  companyName: 'VideoPoc',
  logo: {
    source: {
      uri: 'logo_default.png',
    },
    width: 160,
    height: 71,
  },
  description: 'Monk Capture App enables high level car inspections with helpers and specific user interfaces.',
  vm: true,
  resolution: 'QHD'
};

export default info;
