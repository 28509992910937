const sights = {
  suv: [
    'jgc21-QIvfeg0X', // Front Low
    'jgc21-imomJ2V0', // Front Roof Left
    'jgc21-QIkcNhc_', // Front Fender Left
    'jgc21-TEoi50Ff', // Lateral Full Left
    'jgc21-ezXzTRkj', // Rear Left
    'jgc21-TyJPUs8E', // Rear Low
    'jgc21-3JJvM7_B', // Rear Right
    'jgc21-1j-oTPag', // Lateral Full Right
    'jgc21-QwNQX0Cr', // Front Fender Right
    'IqwSM3', // Front Seats
    'rSvk2C', // Dashboard
  ],
  cuv: [
    'fesc20-H1dfdfvH', // Front Low
    'fesc20-6GPUkfYn', // Front Roof Left
    'fesc20-GdIxD-_N', // Front Fender Left
    'fesc20-26n47kaO', // Lateral Full Left
    'fesc20-dfICsfSV', // Rear Left
    'fesc20-xBFiEy-_', // Rear Low
    'fesc20-LZc7p2kK', // Rear Right
    'fesc20-HYz5ziHi', // Lateral Full Right
    'fesc20-CEGtqHkk', // Front Fender Right
    'IqwSM3', // Front Seats
    'rSvk2C', // Dashboard
  ],
  sedan: [
    'haccord-8YjMcu0D', // Front Low
    'haccord-oiY_yPTR', // Front Roof Left
    'haccord-2a8VfA8m', // Front Fender Left
    'haccord-_YnTubBA', // Lateral Full Left
    'haccord-GdWvsqrm', // Rear Left
    'haccord-6kYUBv_e', // Rear Low
    'haccord-Jq65fyD4', // Rear Right
    'haccord-PGr3RzzP', // Lateral Full Right
    'haccord-EfRIciFr', // Front Fender Right
    'IqwSM3', // Front Seats
    'rSvk2C', // Dashboard
  ],
  hatchback: [
    'ffocus18-XlfgjQb9', // Front Low
    'ffocus18-ZXKOomlv', // Front Roof Left
    'ffocus18-GiTxaJUq', // Front Fender Left
    'ffocus18-6FX31ty1', // Lateral Full Left
    'ffocus18-9MeSIqp7', // Rear Left
    'ffocus18-L2UM_68Q', // Rear Low
    'ffocus18-jWOq2CNN', // Rear Right
    'ffocus18-FdsQDaTW', // Lateral Full Right
    'ffocus18-zgLKB-Do', // Front Fender Right
    'IqwSM3', // Front Seats
    'rSvk2C', // Dashboard
  ],
  van: [
    'ftransit18-wyXf7MTv', // Front Low
    'ftransit18-5SiNC94w', // Front Bumper Side Left
    'ftransit18-rsXWUN8X', // Lateral Full Left
    'ftransit18-iu1Vj2Oa', // Rear Left
    'ftransit18-3dkU10af', // Rear Low
    'ftransit18-FFP5b34o', // Rear Right
    'ftransit18-G24AdP6r', // Lateral Full Right
    'ftransit18-IIVI_pnX', // Front Bumper Side Right
    'IqwSM3', // Front Seats
    'rSvk2C', // Dashboard
  ],
  minivan: [
    'tsienna20-YwrRNr9n', // Front Low
    'tsienna20-is1tpnqR', // Front Roof Left
    'tsienna20-gkvZE2c7', // Front Fender Left
    'tsienna20-4ihRwDkS', // Lateral Full Left
    'tsienna20-1n_z8bYy', // Rear Left
    'tsienna20-qA3aAUUq', // Rear
    'tsienna20--a2RmRcs', // Rear Right
    'tsienna20-uIHdpQ9y', // Lateral Full Right
    'tsienna20-xtDcn3GS', // Front Fender Right
    'IqwSM3', // Front Seats
    'rSvk2C', // Dashboard
  ],
  pickup: [
    'ff150-zXbg0l3z', // Front Low
    'ff150-Ttsc7q6V', // Front Roof Left
    'ff150-wO_fJ3DL', // Front Fender Left
    'ff150-GOx2s_9L', // Lateral Full Left
    'ff150--xPZZd83', // Rear Left
    'ff150-3dkU10af', // Rear Low
    'ff150-t3KBMPeD', // Rear Right
    'ff150-_UIadfVL', // Lateral Full Right
    'ff150-OviO2DlY', // Front Fender Right
    'IqwSM3', // Front Seats
    'rSvk2C', // Dashboard
  ],
};

export default sights;
